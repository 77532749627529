import React from "react";

import StyledLink from "../styled-link/StyledLink";

import offerguideStyle from "./assets/OfferGuide.module.scss";

const OfferGuide = () => {
  return (
    <section
      name="guide"
      className={offerguideStyle["guide"]}
    >
      <div className={offerguideStyle["guide_container"]}>
        <div className={offerguideStyle["guide_description"]}>
          <p>
            Pro zkoušení šatů doporučujeme telefonické objednání předem. Bude
            pro vás připravena kabinka a naše asistentka, která vám pomůže
            vybrat ty nejlepší šaty, ve kterých si váš den D skvěle užijete.
            </p>
          <StyledLink to="/kontakt">Kontakt</StyledLink>
        </div>
      </div>
    </section>
  )
}

export default OfferGuide;