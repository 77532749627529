import React, { 
  // useState, 
  useEffect
} from "react";
import { navigate } from "gatsby";
// import Img from "gatsby-image";
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../../layout/Layout";
// import StyledLink from "../../components/styled-link/StyledLink";
import OfferGuide from "../../components/offer-guide/OfferGuide";

import akceStyle from "./assets/Akce.module.scss";


// import akceCollections from "./../../queries/spolecenskeSatyCollections";

const AkcePage = () => {
  useEffect(() => {
    navigate("/akce/2020-promo");
  }, []);

  // const akceCollectionsQuery = akceCollections();
  // const collectionsList =
  //   akceCollectionsQuery.django.allCategories.edges[0].node.collectionSet.edges;

  // useEffect(() => {
  //   collectionsList.forEach(collection => {
  //     new Image().src =
  //       collection.node.dressSet.edges[0].node.dresspictureSet.edges[0].node.processedPhotoSharp.childImageSharp.fluid;
  //   });
  // }, []);

  // const [selectedCollection, setSelectedCollection] = useState(
  //   collectionsList[0]
  // );

  // const onCollectionHover = name => {
  //   setSelectedCollection(
  //     collectionsList.find(collection => collection.node.name === name)
  //   );
  // };

  return (
    <Layout title="Spolecenske šaty">
      <section name="collections" className={akceStyle["collections"]}>
        <div className={akceStyle["collections_container"]}>
          <div className={akceStyle["collections_title"]}>
            <h1>Svatební šaty</h1>
          </div>
          <div className={akceStyle["collections_content"]}>
            <div className={akceStyle["collections_description"]}>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
                elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                Nulla est. Itaque earum rerum hic tenetur a sapiente delectus,
                ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat. Nullam at arcu a est
                sollicitudin euismod.{" "}
              </p>
            </div>
            <div className={akceStyle["collections_list"]}>
              {/* {collectionsList &&
                collectionsList.map(collection => (
                  <div
                    key={collection.node.id}
                    className={akceStyle["collections_list_item"]}
                  >
                    <StyledLink
                      to={`/svatebni-saty/${collection.node.slug}`}
                      onMouseEnter={() =>
                        onCollectionHover(collection.node.name)
                      }
                    >
                      {collection.node.name}
                    </StyledLink>
                  </div>
                ))} */}
            </div>
          </div>
          <div className={akceStyle["collections_image"]}>
            {/* <img
              className={svatebnisatyStyle["collections_image_placeholder"]}
              src={selectedCollection.image}
              alt="Svatební šaty"
            /> */}
            {/* <Img
              key={selectedCollection.node.dressSet.slug}
              className={akceStyle["collections_image_placeholder"]}
              fluid={
                selectedCollection.node.dressSet.edges[0].node.dresspictureSet
                  .edges[0].node.processedPhotoSharp.childImageSharp.fluid
              }
              alt={selectedCollection.node.dressSet.name}
            /> */}
            <AnimatePresence>
              <motion.div
                className={akceStyle["collections_image_motion"]}
                // key={selectedCollection.name}
                initial={{ opacity: 0, transition: { duration: 0.5 } }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.5, delay: 0.33 }
                }}
              >
                {/* <img
                  key={selectedCollection.name}
                  src={selectedCollection.image}
                  alt={selectedCollection.name}
                /> */}
                {/* <Img
                  key={selectedCollection.node.dressSet.slug}
                  fluid={
                    selectedCollection.node.dressSet.edges[0].node
                      .dresspictureSet.edges[0].node.processedPhotoSharp
                      .childImageSharp.fluid
                  }
                  alt={selectedCollection.node.dressSet.name}
                /> */}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </section>

      <OfferGuide />
    </Layout>
  );
};

export default AkcePage;
